<template>
  
  <div>
        <div>
            <template>
                <v-toolbar flat>
                    <v-toolbar-title>{{$t("Tools")}}</v-toolbar-title>
                    <v-spacer></v-spacer>
                </v-toolbar>
            </template>  
        </div>
        <div class="mt-4">            
            <div class="mt-4">
                <v-row>
                    <v-col cols="2">
                        <v-card class="calc-card" :to="{name: 'LeadsCalculator'}" >
                            <v-card-title>
                                <v-icon class="">mdi-calculator</v-icon>
                            </v-card-title>
                            <v-card-text><p>{{$t("Leads calculator")}}</p></v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="2">
                        <v-card class="calc-card" :to="{name: 'CrossSellCalculator'}" >
                            <v-card-title>
                                <v-icon class="">mdi-calculator</v-icon>
                            </v-card-title>
                            <v-card-text><p>{{$t("Cross sell calculator")}}</p></v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </div>
        </div>
            
    </div>
</template>

<script>
    export default {
        data(){
            return{

            }
        },
        
    }
</script>
<style lang="scss">
    .calc-card .v-card__title{
        text-align: center;
        display: block;
    }

    .calc-card .v-card__text {
        text-align: center;
    }

    .v-card__text p {
        color: #424242;
        font-size: 15px;
        font-weight: 500;
    }    
</style>